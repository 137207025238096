<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="main-banner-content">
            <!-- <span class="sub-title wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Get In Shape</span> -->
            <div class="logo wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1000ms">
                <!-- <img src="assets/img/zelda.png" alt="image"> -->
                <!-- <h1 style="font-size: 48px;">WAIHI BEACH GYM</h1> -->
            </div>
            <!-- <h6 class="wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms">Available Now</h6> -->
            <div class="btn-box wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1000ms">
                <a href="https://oc.debitsuccess.com/DirectEntry/DirectDebitRequest/Form?brandtemplateid=33ebcea5-7514-4e47-bb03-cf964a457251" class="default-btn" target="_blank">Join Now</a>
                <a routerLink="/faq" class="optional-btn">More Info</a>
            </div>
        </div>

        <div class="banner-video-slides owl-carousel owl-theme">
            <div class="banner-video-box">
                <img style="border-radius: 8px;" src="assets/img/wholegym.jpeg" alt="image">

                <div class="content">
                    <!-- <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i></a> -->
                    <!-- <span class="title">Weights</span> -->
                </div>
            </div>

            <div class="banner-video-box">
                <img style="border-radius: 8px;" src="assets/img/boxingbag3.jpeg" alt="image">

                <div class="content">
                    <!-- <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i></a> -->
                    <!-- <span class="title">Boxing</span> -->
                </div>
            </div>

                        <div class="banner-video-box">
                <img style="border-radius: 8px;" src="assets/img/spinbikes.jpeg" alt="image">

                <div class="content">
                    <!-- <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i></a> -->
                    <!-- <span class="title">Boxing</span> -->
                </div>
            </div>
        </div>
    </div>
</div>
