<section class="awards-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="awards-image">
                    <img src="assets/img/ninja.png" alt="image">

                    <div class="shape">
                        <img src="assets/img/circle-shape.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="awards-content">
                    <span class="sub-title">Awards</span>
                    <h2>Game Of The Year</h2>

                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4 col-md-3 col-4">
                            <div class="image">
                                <img src="assets/img/awards1.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-3 col-4">
                            <div class="image">
                                <img src="assets/img/awards2.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-3 col-4">
                            <div class="image">
                                <img src="assets/img/awards3.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-3 col-4">
                            <div class="image">
                                <img src="assets/img/awards4.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-3 col-4">
                            <div class="image">
                                <img src="assets/img/awards5.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="shape">
                        <img src="assets/img/awards-shape1.png" alt="image">
                        <img src="assets/img/awards-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>