<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="section-title">
                <span class="sub-title">We Offer</span>
                <h2>Clean, Modern, Friendly Environment For All</h2>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <img src="../../../../assets/img/time-machine.png" alt="">
                    <!-- <i class="fa fa-home"></i> -->
                    <!-- <mat-icon style="font-size: 22px; height: 22px; width: 22px;" class="navIcons">help</mat-icon> -->
                </div>
                <h3>24/7 Gym</h3>
                <p>Open 24 hours, everyday.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <img src="../../../../assets/img/gym-class.png" alt="">
                </div>
                <h3>Casual Training</h3>
                <p>We allow casual workouts, $10 per workout, call 027 486 6003</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-slot-machine"></i>
                </div>
                <h3>Great Location</h3>
                <p>Located in Waihi Beach.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-3d"></i>
                </div>
                <h3>Personal Training</h3>
                <p>Weight Training, Beginner and Boxing personal training services offered.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-website"></i>
                </div>
                <h3>Friendly Owners</h3>
                <p>Proudly NZ owned and operated by active owner.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-game-controller"></i>
                </div>
                <h3>Clean Gym</h3>
                <p>Our gym is cleaned everyday.</p>
            </div>
        </div>
    </div>
</div>
