<app-navbar-style-one></app-navbar-style-one>

<app-main-home></app-main-home>

<!-- <app-esports-main-banner></app-esports-main-banner> -->

<section class="services-area pt-100 pb-100">
    <app-services></app-services>
</section>

<!-- <app-matches-style-two></app-matches-style-two> -->

<!-- <section class="top-team-area ptb-100">
    <app-top-ranking-team-style-one></app-top-ranking-team-style-one>
</section>

<app-live-stream></app-live-stream>

<section class="products-area ptb-100">
    <app-shop-style-two></app-shop-style-two>
</section> -->

<!-- <div class="partner-area bg-image pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <app-partner></app-partner>
</div>

<section class="blog-area ptb-100">
    <app-blog></app-blog>
</section> -->

<div class="footer-area-bg-image">

    <!-- <section class="subscribe-area">
        <app-subscribe-style-one></app-subscribe-style-one>
    </section> -->

    <section class="social-area pt-100 pb-70">
        <app-social></app-social>
    </section>

    <footer class="footer-area">
        <app-footer-style-one></app-footer-style-one>
    </footer>

</div>
