<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 style="color: rgb(223,19,39)">GET STARTED</h1>
        </div>
    </div>
</section>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Getting Started</span></a></li>

                <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Pricing & Plans</span></a></li>

                <!-- <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Sales Question</span></a></li> -->
<!--
                <li><a href="#"><i class='bx bx-book-open'></i> <span>Usage Guides</span></a></li>

                <li><a href="#"><i class='bx bx-info-circle'></i> <span>General Guide</span></a></li> -->
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How Do I Join?</h3>
                                <p><strong>A:</strong> Pick price structure which suits you (check <a routerLink="/pricing">pricing page</a>), then sign up <a href="https://oc.debitsuccess.com/DirectEntry/DirectDebitRequest/Form?brandtemplateid=33ebcea5-7514-4e47-bb03-cf964a457251">here</a>.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How Do I Arrange Boxing Training With Fred?</h3>
                                <p><strong>A:</strong> Call Fred on 0274866003 anytime and he’ll schedule your first boxing session</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: I've Joined, What Now?</h3>
                                <p><strong>A:</strong> Call Fred, who will arrange to meet you and give you a key for entry to the gym, the key is $15 refundable on return.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s Freds background?</h3>
                                <p><strong>A:</strong> Former world class boxing trainer, previous gym owner, Military experience.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do you have Gold Card memberships?</h3>
                                <p><strong>A:</strong> Yes, we do. See our pricing table here for rates.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How can I cancel my membership?</h3>
                                <p><strong>A:</strong> You can cancel direct debit payments via Debit Success, giving 3 weeks of notice.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What forms of payment do you accept?</h3>
                                <p><strong>A:</strong> Cash, Direct Debit, Automatic Payment.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: I have more questions?</h3>
                                <p><strong>A:</strong> See the pricing table here, and call Fred for anything further.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What does a digital marketer do?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Would my business benefit from digital marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How often should I update my website?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section> -->

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
