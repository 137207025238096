<div class="container">
    <div class="footer-content">
        <div class="logo">
            <a routerLink="/" class="d-inline-block"><img style="width: 100%; max-width: 600px;" src="assets/img/logo.png" alt="image"></a>
            <ul class="footer-menu">
                <li class="nav-item"><a class="nav-link" routerLink="/">Home</a></li>
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/faqs">Get Started</a></li> -->
                <li class="nav-item"><a class="nav-link" routerLink="/pricing">Pricing</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/gallery">Gallery</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/faq">Get Started</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/contact">Contact</a></li>
            </ul>
        </div>
    </div>
</div>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-5">
                <p><i class='bx bx-copyright'></i>{{ correctYear }} <a routerLink="/">Body Mechanics</a> Gym Ltd.</p>
            </div>

            <!-- <div class="col-lg-6 col-md-6 col-sm-7">
                <div class="lang-switcher">
                    <span>Select your language</span>
                    <select>
                        <option>English</option>
                        <option>Spanish</option>
                        <option>French</option>
                        <option>Portuguese</option>
                    </select>
                </div>
            </div> -->
        </div>
    </div>
</div>
