<div class="hero-banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="hero-banner-image text-center">
                    <img src="assets/img/gymfront.jpeg" style="height: 650px; width: 650px;" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="hero-banner-content">
                    <div class="content">
                        <h1>Great Gym Waihi Beach Location</h1>
                        <p>24/7 Gym</p>
                        <p>Great Equipment</p>
                        <p>Friendly Owners</p>
                        <p>Personal Training Available</p>

                        <div class="btn-box">
                            <a routerLink="/team" class="default-btn">Meet Us</a>
                            <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
