<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="section-title">
                <span class="sub-title">Team Members</span>
                <h2>Meet Our Team The "Junior Zinble"</h2>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member1.jpg" alt="image">

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Sarah3s</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member2.jpg" alt="image">

                <div class="content">
                    <h3>Merv Adrian</h3>
                    <span>Zelda3s</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member3.jpg" alt="image">

                <div class="content">
                    <h3>Carla Gentry</h3>
                    <span>3s.King</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member4.jpg" alt="image">

                <div class="content">
                    <h3>Liam Oliver</h3>
                    <span>Junior.Zelda</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member5.jpg" alt="image">

                <div class="content">
                    <h3>Emma Olivia</h3>
                    <span>King.Zelda</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-team-member">
                <img src="assets/img/team-member6.jpg" alt="image">

                <div class="content">
                    <h3>Elijah William</h3>
                    <span>Zelda</span>
                </div>

                <a routerLink="/single-player" class="link-btn"></a>
            </div>
        </div>
    </div>
</div>