<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 style="color: rgb(223,19,39);">Pricing</h1>
        </div>
    </div>
</section>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Membership Type</th>
                            <th scope="col">Joining Fee</th>
                            <th scope="col">Admin Fee</th>
                            <th scope="col">Weekly Rate</th>
                            <th scope="col">First Week Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a>
                                   12-Month 24/7
                                </a>
                            </td>

                            <td class="product-name">
                                <a>
                                    $50.00
                                </a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$15.00</span>
                            </td>

                            <td class="product-quantity">
                                <span class="unit-amount">$15.00</span>
                                <!-- <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div> -->
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$80</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a>
                                   6-Month 24/7
                                </a>
                            </td>

                            <td class="product-name">
                                <a>
                                    $50.00
                                </a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$15.00</span>
                            </td>

                            <td class="product-quantity">
                                <span class="unit-amount">$18.00</span>
                                <!-- <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div> -->
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$83.00</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a>
                                   3-Month 24/7
                                </a>
                            </td>

                            <td class="product-name">
                                <a>
                                    $50.00
                                </a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$15.00</span>
                            </td>

                            <td class="product-quantity">
                                <span class="unit-amount">$20.00</span>
                                <!-- <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div> -->
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$85.00</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a>
                                   Seniors (Gold Card)
                                </a>
                            </td>

                            <td class="product-name">
                                <a>
                                    $25.00
                                </a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$15.00</span>
                            </td>

                            <td class="product-quantity">
                                <span class="unit-amount">$15.00</span>
                                <!-- <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div> -->
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$50.00</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                        </tr>
<!--
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products-img2.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">GAMING CHAIR</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$199.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$199.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products-img3.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">GAMING MOUSE</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$69.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$69.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/products-img4.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">GAMING PAD</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$299.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$299.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr> -->
                    </tbody>
                </table>

                <h5 style="text-align: center; margin-top: 20px;">Casual - 1 Week - $35 + $20 (for refundable key tag)</h5>
                <h5 style="text-align: center; margin-top: 10px;">Casual - 1 Session - $10</h5>

            </div>

            <!-- <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <a routerLink="/cart" class="default-btn">Update Cart</a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <li>Subtotal <span>$800.00</span></li>
                    <li>Shipping <span>$30.00</span></li>
                    <li>Total <span>$830.00</span></li>
                </ul>
                <a routerLink="/checkout" class="default-btn">Proceed to Checkout</a>
            </div> -->
        </form>
    </div>
</section>

<!-- <section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section> -->

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
