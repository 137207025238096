<div class="home-slides owl-carousel owl-theme">
    <div class="single-banner-item banner-bg1">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title" style="font-weight: 800; letter-spacing: 1px !important; font-size: 36px;">WAIHI BEACH GYM</span>
                        <!-- <div class="logo">
                            <img src="assets/img/" alt="image">
                        </div> -->
                        <h6>Want to get in Shape?</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Join Now</a>
                            <a routerLink="/contact" class="optional-btn">FAQs</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img style="border-radius: 8px;" src="assets/img/wholegym.jpeg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-banner-item banner-bg1">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title" style="font-weight: 800; letter-spacing: 1px !important; font-size: 36px;">WAIHI BEACH GYM</span>
                        <!-- <div class="logo">
                            <img src="assets/img/" alt="image">
                        </div> -->
                        <h6>Want to get in Shape?</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Join Now</a>
                            <a routerLink="/contact" class="optional-btn">FAQs</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img style="border-radius: 8px;" src="assets/img/boxingbag3.jpeg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-banner-item banner-bg1">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title" style="font-weight: 800; letter-spacing: 1px !important; font-size: 36px;">WAIHI BEACH GYM</span>
                        <!-- <div class="logo">
                            <img src="assets/img/" alt="image">
                        </div> -->
                        <h6>Want to get in Shape?</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Join Now</a>
                            <a routerLink="/contact" class="optional-btn">FAQs</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img style="border-radius: 8px;" src="assets/img/spinbikes.jpeg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
