<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Contact Us">Contact Us</h1>
        </div>
    </div>
</section>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                    <p>Located in Waihi Beach, available for phone calls.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>57 Wilson Road, Waihi Beach</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-24-hours"></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+64274866003">(027) 486-6003</a></p>
                            <p>Mail: <a href="mailto:body.mechanics@outlook.com">body.mechanics@outlook.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Our Facebook</h3>
                            <div class="social-box">
                                <!-- <a href="#" target="_blank"><i class="bx bxl-twitch"></i></a> -->
                                <a href="https://www.facebook.com/bodymechanicswb" target="_blank"><i class="bx bxl-facebook"></i></a>
                                <!-- <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a> -->
                                <!-- <a href="#" target="_blank"><i class="bx bxl-youtube"></i></a>
                                <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
                                <a href="#" target="_blank"><i class="bx bxl-vimeo"></i></a> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <!-- <h2>Fred Frauendorf</h2> -->
                    <!-- <p>Your email address will not be published. Required fields are marked *</p> -->
                    <!-- <img style="border-radius: 8px;" src="../../../../assets/img/gymfront.jpeg"/> -->
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.1792336153576!2d175.93573161530713!3d-37.40923607982777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6ded51dc9909ad%3A0xdea5ed7a151cf97c!2s57+Wilson+Rd%2C+Waihi+Beach+3611!5e1!3m2!1sen!2snz!4v1473812091888" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section> -->

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
