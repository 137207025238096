<app-navbar-style-one></app-navbar-style-one>

<app-main-banner></app-main-banner>

<!-- <section class="social-area pt-100 pb-70">
    <app-social></app-social>
</section>

<footer class="footer-area">
    <app-footer-style-one></app-footer-style-one>
</footer> -->
